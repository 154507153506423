<template>
  <!--begin::Table Skeleton-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h5 class="card-title align-items-start flex-column">
        <span class="card-label fs-3 mb-1 text-muted">Loading Data...</span>
      </h5>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
            skeleton
          "
        >
          <!--begin::Table body-->
          <tbody>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Table Skeleton-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "table-skeleton",
});
</script>

<style scoped>
@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}
.skeleton p {
  content: " ";
  width: 100%;
  background-color: #f5f8fa;
  border-radius: 7px;
  height: 24px;
  line-height: 140%;
  animation: pulse-bg 1s infinite;
}

.skeleton .text-end .svg-icon,
.card-toolbar span {
  background-color: #ddd;
  height: 16px;
}
</style>
